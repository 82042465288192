<template>
  <ul class="navbar-nav sidebar sidebar-dark accordion custom-bg custom-text" id="accordionSidebar" :style="updateCSS">
    <!-- Divider -->
    <hr class="sidebar-divider" />

    <!-- Sidebar - Company Logo -->
    <div class="text-center">
      <div class="d-flex align-items-center justify-content-between m-1">
        <router-link class="mb-0" to="/">
          <div class="mb-0">
            <img class="main-logo" src="/img/main/1.jpg" />
          </div>
        </router-link>
      </div>
    </div>

    <!-- Divider -->
    <hr class="sidebar-divider" />
    <hr class="sidebar-divider" />

    <!-- User Information -->
    <div class="text-center">
      <img v-if="user.avatar" class="avatar-img rounded-circle" :src="'/img/avatar/' + user.avatar + '.png'" />
    </div>
    <div class="text-center my-2" @click="changeArrow()" data-toggle="collapse" data-target="#collapseExample"
      aria-expanded="false" aria-controls="collapseExample">
      <div>{{ user.last_name }}</div>
      <i v-if="arrow" class="fa fa-angle-down" :style="routerColor"></i>
      <i v-else class="fa fa-angle-up" :style="routerColor"></i>
    </div>

    <div class="collapse m-0 p-0" id="collapseExample">
      <!-- User Editor -->
      <li class="nav-item" @click="activeClick = 'profileedit'" @mouseover="activeHover = 'profileedit'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'profileedit' === activeClick, 'active-Hover': 'profileedit' === activeHover }">
        <router-link class="nav-link text-center border-1" :to="{ name: 'profileedit', params: { id: user.id } }">
          <i class="fas fa-edit fa-5x" :style="routerColor"></i>
          <span :style="routerColor">Profile</span>
        </router-link>
      </li>
      <li class="nav-item">
        <div class="nav-link text-center border-1" @click="logout" @mouseover="activeHover = 'Logout'"
          @mouseleave="activeHover = null" :class="{ 'active-Hover': 'Logout' === activeHover }">
          <i class="fas fa-sign-out-alt fa-5x" :style="routerColor"></i>
          <span :style="routerColor">Logout</span>
        </div>
      </li>
    </div>

    <!-- Divider -->
    <hr class="sidebar-divider" />
    <hr class="sidebar-divider" />

    <div class="sidebar-heading" :style="routerColor">Monitor</div>

    <!-- Nav Item - Live RTU -->
    <li class="nav-item" @click="activeClick = 'livertu'" @mouseover="activeHover = 'livertu'"
      @mouseleave="activeHover = null"
      :class="{ 'active-Click': 'livertu' === activeClick, 'active-Hover': 'livertu' === activeHover }">
      <router-link class="nav-link" :to="{ name: 'livertu' }">
        <i class="fas fa-chart-area fa-5x" :style="routerColor"></i>
        <span :style="routerColor">Data Report</span>
      </router-link>
    </li>

    <!-- Nav Item - Comparasion -->
    <li class="nav-item" @click="activeClick = 'rtucompare'" @mouseover="activeHover = 'rtucompare'"
      @mouseleave="activeHover = null"
      :class="{ 'active-Click': 'rtucompare' === activeClick, 'active-Hover': 'rtucompare' === activeHover }">
      <router-link class="nav-link" :to="{ name: 'rtucompare' }">
        <i class="fas fa-code-compare fa-5x" :style="routerColor"></i>
        <span :style="routerColor">Comparison</span>
      </router-link>
    </li>

    <!-- Nav Item - RTU Usage -->
    <li class="nav-item" @click="activeClick = 'rtuusage'" @mouseover="activeHover = 'rtuusage'"
      @mouseleave="activeHover = null"
      :class="{ 'active-Click': 'rtuusage' === activeClick, 'active-Hover': 'rtuusage' === activeHover }">
      <router-link class="nav-link" :to="{ name: 'rtuusage' }">
        <i class="fas fa-calendar-day fa-5x" :style="routerColor"></i>
        <span :style="routerColor">RTU Usage</span>
      </router-link>
    </li>

    <!-- Nav Item - Cluster Maps -->
    <li class="nav-item" @click="activeClick = 'clustermap'" @mouseover="activeHover = 'clustermap'"
      @mouseleave="activeHover = null"
      :class="{ 'active-Click': 'clustermap' === activeClick, 'active-Hover': 'clustermap' === activeHover }">
      <router-link class="nav-link" :to="{ name: 'clustermap' }">
        <i class="fas fa-location-dot fa-5x" :style="routerColor"></i>
        <span :style="routerColor">Cluster Map</span>
      </router-link>
    </li>


    <!-- If this user admin -->
    <div v-if="user.role">

      <!-- Divider -->
      <hr class="sidebar-divider" :style="routerColor" />
      <hr class="sidebar-divider" :style="routerColor" />


      <!-- Heading -->
      <div class="sidebar-heading" :style="routerColor">Configuration</div>

      <!-- Nav Item - Cluster Configure -->
      <li class="nav-item" @click="activeClick = 'clusterlist'" @mouseover="activeHover = 'clusterlist'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'clusterlist' === activeClick, 'active-Hover': 'clusterlist' === activeHover }">
        <router-link class="nav-link" :to="{ name: 'clusterlist' }">
          <i class="fas fa-pen-to-square fa-5x" :style="routerColor"></i>
          <span :style="routerColor">Cluster</span>
        </router-link>
      </li>

      <!-- Nav Item - RTU Configure -->
      <li class="nav-item" @click="activeClick = 'rtulist'" @mouseover="activeHover = 'rtulist'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'rtulist' === activeClick, 'active-Hover': 'rtulist' === activeHover }">
        <router-link class="nav-link" :to="{ name: 'rtulist' }">
          <i class="fas fa-table fa-5x" :style="routerColor"></i>
          <span :style="routerColor">RTU</span>
        </router-link>
      </li>

      <!-- Nav Item - Data Configure -->
      <li class="nav-item" @click="activeClick = 'parameter'" @mouseover="activeHover = 'parameter'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'parameter' === activeClick, 'active-Hover': 'parameter' === activeHover }">
        <router-link class="nav-link" :to="{ name: 'parameter' }">
          <i class="fas fa-chess-board fa-5x" :style="routerColor"></i>
          <span :style="routerColor">Parameter</span>
        </router-link>
      </li>

      <!-- Nav Item - Templates Configure -->
      <li class="nav-item" @click="activeClick = 'templates'" @mouseover="activeHover = 'templates'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'templates' === activeClick, 'active-Hover': 'templates' === activeHover }">
        <router-link class="nav-link" :to="{ name: 'templates' }">
          <i class="fas fa-list fa-5x" :style="routerColor"></i>
          <span :style="routerColor">Templates</span>
        </router-link>
      </li>

      <!-- Nav Item - Widget Configure -->
      <li class="nav-item" @click="activeClick = 'widget'" @mouseover="activeHover = 'widget'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'widget' === activeClick, 'active-Hover': 'widget' === activeHover }">
        <router-link class="nav-link" :to="{ name: 'widget' }">
          <i class="fas fa-cubes fa-5x" :style="routerColor"></i>
          <span :style="routerColor">Widget</span>
        </router-link>
      </li>

      <!-- Nav Item - Widget Configure -->
      <li class="nav-item" @click="activeClick = 'scheduler'" @mouseover="activeHover = 'scheduler'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'scheduler' === activeClick, 'active-Hover': 'scheduler' === activeHover }">
        <router-link class="nav-link" :to="{ name: 'scheduler' }">
          <i class="fas fa-calendar-day fa-5x" :style="routerColor"></i>
          <span :style="routerColor">Scheduler</span>
        </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider" :style="routerColor" />
      <hr class="sidebar-divider" :style="routerColor" />

      <!-- Heading -->
      <div class="sidebar-heading" :style="routerColor">Admin</div>

      <!-- Nav Item - User Configure -->
      <li class="nav-item" @click="activeClick = 'userlist'" @mouseover="activeHover = 'userlist'"
        @mouseleave="activeHover = null"
        :class="{ 'active-Click': 'userlist' === activeClick, 'active-Hover': 'userlist' === activeHover }">
        <router-link class="nav-link" :to="{ name: 'userlist' }">
          <i class="fas fa-user fa-5x" :style="routerColor"></i>
          <span :style="routerColor">User</span>
        </router-link>
      </li>

      <!-- Nav Item - Group Configure -->
      <div v-if="user.isMaster">
        <li class="nav-item" @click="activeClick = 'grouplist'" @mouseover="activeHover = 'grouplist'"
          @mouseleave="activeHover = null"
          :class="{ 'active-Click': 'grouplist' === activeClick, 'active-Hover': 'grouplist' === activeHover }">
          <router-link class="nav-link" :to="{ name: 'grouplist' }">
            <i class="fas fa-people-roof fa-5x" :style="routerColor"></i>
            <span :style="routerColor">Group</span>
          </router-link>
        </li>
      </div>
    </div>
    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" :style="routerColor" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center">
      <button class="rounded-circle border-0" id="sidebarToggle" :style="routerColor">
      </button>
    </div>
  </ul>
  <!-- End of Sidebar -->
</template>

<script>
import { mapGetters } from "vuex"

var repeatlastSeen
export default {
  name: "Sidebar",
  data() {
    return {
      arrow: true,
      activeClick: this.$route.name,
      activeHover: null,
    }
  },
  computed: {
    ...mapGetters(["user"]),
    updateCSS() {
      if (this.$store.getters.customBar == null) {
        this.$store.dispatch('customBar', { background: '98, 27, 59', text: '#ffffff' })
      }
      return {
        '--bg-color': this.$store.getters.customBar.background,
        '--text-color': this.$store.getters.customBar.text,
      }
    },
    routerColor() {
      return {
        'color': this.$store.getters.customBar.text + ' !important',
      }
    },
  },
  created() {
    var self = this
    this.last_seen()
    repeatlastSeen = setInterval(self.last_seen(), 60000)
  },
  methods: {
    last_seen() {
      axios.post("api/lastseen", {
        id: this.user.id,
      })
    },
    logout() {
      localStorage.clear()
      this.$store.dispatch("user", null);
      this.$store.dispatch("customBar", null);
      this.$store.dispatch("token", null);
      this.$router.push("/login");
    },
    changeArrow() {
      this.arrow = !this.arrow
    },
  },
};
</script>

<style  lang="scss">
#sidebarToggle {
  &::after {
    font-family: 'Font Awesome 6 Free' !important;
  }
}

.sidebar {
  &.toggled {
    #sidebarToggle::after {
      font-family: 'Font Awesome 6 Free' !important;
    }
  }
}

.sidebar {
  .nav-link {
    &[data-toggle="collapse"] {
      &::after {
        font-family: 'Font Awesome 6 Free' !important;
      }
    }
  }
}
</style>

<style>
i {
  user-select: none;
}

.navbar {
  user-select: none;
}

.table-custom tr:last-child td:last-child {
  border: none;
}

.active-Click {
  background-color: rgba(59, 52, 52, 0.4);
}

.active-Hover {
  background-color: rgba(59, 52, 52, 0.4);
}

.etc-icon {
  height: 1.5em;
  object-fit: cover;
}

.weather-icon {
  object-fit: cover;
}

.main-logo {
  object-fit: cover;
  width: 90%;
}

.avatar-img {
  object-fit: cover;
  width: 35%;
}

.i-rounded {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0 0 2px #fff;
  background-color: rgba(194, 194, 194, 0.5);
  padding: 0.5em 0.6em;
}

a[aria-expanded=true] .fa-chevron-down {
  display: none;
}

a[aria-expanded=false] .fa-chevron-up {
  display: none;
}
</style>
