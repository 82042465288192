<template>
  <div class="
      navbar navbar-expand
      topbar
      mb-1
      static-top
      custom-bg
      custom-text
    " :style="customBgVars">
    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3" :style="routerColor()">
      <i class="fa fa-bars"></i>
    </button>

    <div class="m-2">
      <h1 class="h5 mb-2">Data Logger, Monitoring, Analyse, Control</h1>
    </div>

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - Search Dropdown (Visible Only XS) -->
      <li class="nav-item dropdown no-arrow d-sm-none" :style="routerColor()">
        <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-search fa-fw"></i>
        </a>
        <!-- Dropdown - Messages -->
        <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
          <form class="form-inline mr-auto w-100 navbar-search">
            <div class="input-group">
              <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                aria-label="Search" aria-describedby="basic-addon2" />
              <div class="input-group-append">
                <button class="btn btn-primary" type="button">
                  <i class="fas fa-search fa-sm"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </li>

      <!-- Nav Item - Alerts -->
      <li class="nav-item dropdown no-arrow mx-1">
        <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-bell fa-fw"></i>
          <!-- Counter - Alerts -->
          <span class="badge badge-danger badge-counter"></span>
        </a>
        <!-- Dropdown - Alerts -->
        <div class="
            dropdown-list dropdown-menu dropdown-menu-right
            shadow
            animated--grow-in
          " aria-labelledby="alertsDropdown">
          <h6 class="dropdown-header">Alerts Center</h6>
          <a class="dropdown-item d-flex align-items-center" href="#">
            <div class="mr-3">
              <div class="icon-circle bg-primary">
                <i class="fas fa-file-alt"></i>
              </div>
            </div>
            <div>
              <div class="small text-gray-500">July 1st, 2022</div>
              <span class="font-weight-bold">A new monthly report is ready to download at Dashboard.</span>
            </div>
          </a>
        </div>
      </li>

      <!-- Nav Item - Messages -->
      <li class="nav-item dropdown no-arrow mx-1">
        <a class="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-envelope fa-fw"></i>
          <!-- Counter - Messages -->
          <span class="badge badge-danger badge-counter">1</span>
        </a>
        <!-- Dropdown - Messages -->
        <div class="
            dropdown-list dropdown-menu dropdown-menu-right
            shadow
            animated--grow-in
          " aria-labelledby="messagesDropdown">
          <h6 class="dropdown-header">Message Center</h6>
          <a class="dropdown-item d-flex align-items-center" href="#">
            <div class="dropdown-list-image mr-3">
              <img class="rounded-circle" src="/img/main/5.png" alt="..." />
              <div class="status-indicator bg-success"></div>
            </div>
            <div class="font-weight-bold">
              <div class="text-truncate">
                CLOG - New updates. Check it out.
              </div>
              <div class="small text-gray-500">July 19th, 2022</div>
            </div>
          </a>
        </div>
      </li>
    </ul>
    <div class="topbar-divider d-none d-sm-block"></div>

    <div class="d-sm-flex align-items-center justify-content-between rounded-circle my-4 mx-1">
      <color-picker v-model="backgroundColor" @change="bgChange" :position="{ right: 0, top: '40px' }"
        style="border-radius:5px;border:2px solid black" v-tooltip.left="'Background color'"></color-picker>
    </div>

    <div class="d-sm-flex align-items-center justify-content-between rounded-circle my-4 mx-1">
      <color-picker v-model="textColor" @change="textChange" :position="{ right: 0, top: '40px' }"
        style="border-radius:5px;border:2px solid black" v-tooltip.left="'Text color'"></color-picker>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "Topbar",
  computed: {
    ...mapGetters(["user"]),
    customBgVars() {
      if (this.$store.getters.customBar == null) {
        this.$store.dispatch('customBar', { background: '98, 27, 59', text: '#ffffff' })
      }
      return {
        '--bg-color': this.$store.getters.customBar.background,
        '--text-color': this.$store.getters.customBar.text,
      }
    }
  },
  data() {
    var background = this.rgbToHex('rgb(' + this.$store.getters.customBar.background + ')')
    return {
      backgroundColor: background,
      textColor: this.$store.getters.customBar.text,
    }
  },
  methods: {
    bgChange() {
      var background = this.hexToRgb(this.backgroundColor)
      this.$store.dispatch('customBar', { background: background, text: this.textColor })
    },
    textChange() {
      var background = this.hexToRgb(this.backgroundColor)
      this.$store.dispatch('customBar', { background: background, text: this.textColor })
    },
    logout() {
      this.$store.dispatch("user", null);
      this.$store.dispatch("token", null);
      this.$router.push("/login");
    },
    routerColor() {
      return {
        'color': this.$store.getters.customBar.text + ' !important',
      }
    },
    hexToRgb(hex) {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return `${r}, ${g}, ${b}`;
    },
    rgbToHex(rgb) {
      if (~rgb.indexOf("#")) return rgb
      rgb = rgb.replace(/[^\d,]/g, "").split(",")
      return "#" + ((1 << 24) + (+rgb[0] << 16) + (+rgb[1] << 8) + +rgb[2]).toString(16).slice(1)
    }
  },
};
</script>
