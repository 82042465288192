import Vue from "vue";

import Router from "vue-router";
import store from "./vuex";
import AdminLayout from "./views/admin/layout/index";

Vue.use(Router);

let router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("./views/admin/livertu.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/clustermap",
            name: "clustermap",
            component: () => import("./views/admin/clustermap.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/rtucompare",
            name: "rtucompare",
            component: () => import("./views/admin/rtucompare.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/rtuusage",
            name: "rtuusage",
            component: () => import("./views/admin/rtuusage.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/login/:user_id?",
            name: "login",
            component: () => import("./views/login/index.vue")
        },
        {
            path: "/register",
            name: "register",
            component: () => import("./views/register/index.vue")
        },
        {
            path: "/verify/user/:id",
            name: "verify",
            props: true,
            component: () => import("./views/verify/index.vue")
        },
        {
            path: "/forgot-password",
            name: "forgot",
            component: () => import("./views/forgot/index.vue")
        },
        {
            path: "/reset/:token",
            name: "reset",
            component: () => import("./views/reset/index.vue")
        },
        /**
         * Admin routes
         */
        {
            path: "/livertu",
            name: "livertu",
            component: () => import("./views/admin/livertu.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/profile/edit/:id",
            name: "profileedit",
            component: () => import("./views/admin/profile/profileedit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/user",
            name: "userlist",
            component: () => import("./views/admin/user/user.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/user/add",
            name: "useradd",
            component: () => import("./views/admin/user/useradd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/user/edit/:id",
            name: "useredit",
            component: () => import("./views/admin/user/useredit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/group",
            name: "grouplist",
            component: () => import("./views/admin/group/group.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/group/add",
            name: "groupadd",
            component: () => import("./views/admin/group/groupadd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/group/edit/:id",
            name: "groupedit",
            component: () => import("./views/admin/group/groupedit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/cluster",
            name: "clusterlist",
            component: () => import("./views/admin/cluster/cluster.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/cluster/add",
            name: "clusteradd",
            component: () => import("./views/admin/cluster/clusteradd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/cluster/edit/:id",
            name: "clusteredit",
            component: () => import("./views/admin/cluster/clusteredit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/rtu",
            name: "rtulist",
            component: () => import("./views/admin/rtu/rtu.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/rtu/add",
            name: "rtuadd",
            component: () => import("./views/admin/rtu/rtuadd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/rtu/edit/:id",
            name: "rtuedit",
            component: () => import("./views/admin/rtu/rtuedit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/parameter",
            name: "parameter",
            component: () => import("./views/admin/data/data.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/parameter/add",
            name: "parameteradd",
            component: () => import("./views/admin/data/dataadd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/parameter/edit/:id",
            name: "parameteredit",
            component: () => import("./views/admin/data/dataedit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/templates",
            name: "templates",
            component: () => import("./views/admin/template/template.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/templates/add",
            name: "templatesadd",
            component: () => import("./views/admin/template/templateadd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/templates/edit/:id",
            name: "templatesedit",
            component: () => import("./views/admin/template/templateedit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },{
            path: "/admin/widget",
            name: "widget",
            component: () => import("./views/admin/widget/widget.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/widget/add",
            name: "widgetadd",
            component: () => import("./views/admin/widget/widgetadd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/widget/edit/:id",
            name: "widgetedit",
            component: () => import("./views/admin/widget/widgetedit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },{
            path: "/admin/scheduler",
            name: "scheduler",
            component: () => import("./views/admin/scheduler/scheduler.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/scheduler/add",
            name: "scheduleradd",
            component: () => import("./views/admin/scheduler/scheduleradd.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/scheduler/edit/:id",
            name: "scheduleredit",
            component: () => import("./views/admin/scheduler/scheduleredit.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/page-not-allowed",
            name: "page-not-allowed",
            component: () => import("./views/admin/page-not-allowed.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/pages/page-not-found",
            name: "page-not-found",
            component: () => import("./views/admin/page-not-found.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/pages/blank",
            name: "blank",
            component: () => import("./views/admin/blank.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
        {
            path: "/admin/pages/animations",
            name: "animations",
            component: () => import("./views/admin/animations.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.user) {
            next()
            return
        }
        next("/login")
    } else {
        next()
    }
});

export default router;
