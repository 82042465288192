/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
require("hammerjs");
require("chartjs-adapter-moment");

import router from "./router";
import "./axios";
import store from "./vuex"; //https://vuex.vuejs.org/
import Toasted from "vue-toasted"; //https://github.com/shakee93/vue-toasted
import "hammerjs"; //https://hammerjs.github.io/getting-started/
import _ from 'lodash'; //https://github.com/lodash/lodash
import VuejsDialog from 'vuejs-dialog'; //https://github.com/Godofbrowser/vuejs-dialog
import VueSelectImage from 'vue-select-image'; //https://github.com/mazipan/vue-select-image
import VTitle from 'vue-directive-tooltip'; //https://hekigan.github.io/vue-directive-tooltip/#/setup
import * as VueGoogleMaps from "vue2-google-maps"; // https://www.npmjs.com/package/vue2-google-maps  // eg https://codesandbox.io/s/n3y01wlyzm?file=/src/App.vue:32-46
import {ColorPicker, ColorPanel} from 'one-colorpicker'; // https://vuejsexamples.com/a-nice-chrome-style-colorpicker-with-vue-js/
import Multiselect from 'vue-multiselect';

window.Vue = require("vue").default
window.zoomPlugin = require("chartjs-plugin-zoom").default // https://github.com/chartjs/chartjs-plugin-zoom/blob/v0.7.7/README.md
window.annotationPlugin = require("chartjs-plugin-annotation").default // https://github.com/chartjs/chartjs-plugin-zoom/blob/v0.7.7/README.md
window.Chart = require("chart.js/auto").default
window.Interaction = require("chart.js").Interaction

Vue.use(VuejsDialog)
Vue.use(VueSelectImage)
Vue.use(VTitle, {
    delay: 0
})
Vue.use(Toasted);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDq1hMsF6Xi6uwvz5bj4UBA_iheQagVrjo",
        libraries: "places", // necessary for places input
        region: "sg,en"
    }
})
Vue.use(ColorPanel)
Vue.use(ColorPicker)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("app", require("./App.vue").default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    router,
    store,
    el: "#app"
});
