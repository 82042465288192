import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const state = {
    user: null,
    customBar: null,
    token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
};

const store = new Vuex.Store({
    state,
    getters: {
        user: (state) => {
            return state.user
        },
        customBar(state) {
            return state.customBar
        },
        token: (state) => {
            return state.token
        },
    },
    actions: {
        user: (context, user) => {
            context.commit('user', user)
        },
        customBar: (context, customBar) => {
            context.commit('customBar', customBar)
        },
        token: (context, token) => {
            localStorage.setItem("token", token)
            context.commit('token', token)
        },
    },
    mutations: {
        user: (state, user) => {
            state.user = user
        },
        customBar: (state, customBar) => {
            state.customBar = customBar
        },
        
        token: (state, token) => {
            state.token = token
        },
    },
    plugins: [createPersistedState()]
})

export default store
